import { ApolloLink, DocumentNode, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { matchPath } from 'react-router-dom';
import { AuthStatus, getAuthStatus } from 'src/auth';
import { routes as routesBestOfRing } from 'src/features/BestOfRing/routes';
import { history } from 'src/history';
import { useHasWebViewUserAgent } from 'src/hooks';
import {
  getEventSecurityLogContext,
  logError,
  logErrorIf,
  logInfo,
} from 'src/logs';
import { AppRoutes } from 'src/routes';
import { goToLogin, pathWithWV, reLoginWebview } from 'src/utils';
import { NeighborsErrorCodes } from './codes';
import { hasGqlErrorCodes } from './utils';

const httpLink = new HttpLink({
  uri: '/query',
});

const roundTripLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  operation.setContext({ start: window.performance.now() });

  return forward(operation).map((data) => {
    // Called after server responds
    const ms = window.performance.now() - operation.getContext().start;
    const time = `${ms.toFixed(2)}ms`;
    const { operationName, extensions, variables } = operation;
    const message = `${operationName}: ${time}`;
    const successStatus = 'success: true';

    const context = {
      successStatus,
      operationName,
      extensions,
      variables,
    };

    logInfo(message, getEventSecurityLogContext(context));

    return data;
  });
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const operationName = operation.operationName;
  const successStatus = 'success: false';

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, extensions } = error;
      const notFound = extensions?.code === NeighborsErrorCodes.NotFound;

      const finalMessage = `[GraphQL error]: ${message}`;

      const context = {
        successStatus,
        operationName,
        error,
      };

      logErrorIf(!notFound, finalMessage, getEventSecurityLogContext(context));
    });
  }

  if (networkError) {
    const message = `[Network error]: ${networkError.message}`;

    const context = {
      successStatus,
      operationName,
      networkError,
    };

    logError(message, getEventSecurityLogContext(context));
  }
});

const redirectLink = onError((error) => {
  const {
    location: { pathname },
  } = history;
  const isWebview = useHasWebViewUserAgent();

  const noRedirectPagesMatch = matchPath(pathname, {
    path: [
      AppRoutes.NotFound,
      AppRoutes.EventDetailOrSharePage,
      AppRoutes.BetterNeighborhoods,
      AppRoutes.Deals,
      AppRoutes.PetTag,
      AppRoutes.EmergencyInfo,
      AppRoutes.Terms,
      AppRoutes.PetMarketingPage,
      AppRoutes.PetTagFlyer,
      AppRoutes.PetProfile,
      ...pathWithWV(AppRoutes.PetTagFlyer),
      ...pathWithWV(AppRoutes.PetProfile),
      ...pathWithWV(AppRoutes.BestOfRing + routesBestOfRing.BestOfRingFeed),
      ...pathWithWV(
        AppRoutes.BestOfRing + routesBestOfRing.BestOfRingVideoFullscreen,
      ),
    ],
  });

  const authStatus = getAuthStatus();

  // we need to avoid redirecting if:
  //   * the user is visiting the event page
  //   * the user is visiting the better neighborhoods page AND the webview search param is present
  if (
    noRedirectPagesMatch?.isExact &&
    [AuthStatus.pending, AuthStatus.unauthenticated].includes(authStatus)
  ) {
    return;
  }

  const unauthorizedOrSessionTimedOut = hasGqlErrorCodes(error, [
    NeighborsErrorCodes.Unauthorized,
    NeighborsErrorCodes.SessionTimeout,
  ]);

  if (unauthorizedOrSessionTimedOut) {
    if (isWebview) {
      reLoginWebview();
    } else {
      goToLogin();
    }
  }
});

interface DocumentNodeWithId extends DocumentNode {
  documentId?: string;
}

const persistedQueriesLink = createPersistedQueryLink({
  generateHash: ({ documentId }: DocumentNodeWithId) => documentId!,
});

export const link = from(
  [
    errorLink,
    redirectLink,
    persistedQueriesLink,
    roundTripLink,
    httpLink,
  ].filter(Boolean) as ApolloLink[],
);
