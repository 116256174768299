import { JSX, useState } from 'react';
import { useToggle } from 'react-use';
import { Box, Button, FormControl } from '@amzn/ring-ui-react-components';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '../Modal';
import { useIsDesktop } from 'src/shared/hooks';
import {
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownList,
} from '@amzn/ring-safe-web';
import { FormLabel } from 'src/shared/components/Form';
import momentTimezone from 'moment-timezone';
import { ScheduledEvent } from 'src/utils';
import { createDateTimeFromISO } from 'src/shared/utils';
import { useTranslation } from 'react-i18next';
import { callAllHandlers } from '@chakra-ui/utils';

const HourList = (
  scheduledFor: momentTimezone.Moment,
  handleHourClick: (value: number) => void,
): JSX.Element => {
  const hours = [...Array(12).keys()].map((i) => (
    <DropdownItem
      key={i}
      onClick={() => handleHourClick(i)}
      isChecked={i === scheduledFor.hour()}
    >
      {i.toString().padStart(2, '0')}
    </DropdownItem>
  ));

  return (
    <DropdownList
      isOpen={false}
      style={{ maxHeight: '126px', overflowY: 'auto' }}
    >
      {hours}
    </DropdownList>
  );
};

const MinutesList = (
  scheduledFor: momentTimezone.Moment,
  handleTimeClick: (value: string) => void,
): JSX.Element => {
  const minutesList = [...Array(4).keys()].map((n) => {
    const minute = (n * 15).toString().padStart(2, '0');

    return (
      <DropdownItem
        key={minute}
        onClick={() => handleTimeClick(minute)}
        isChecked={minute === scheduledFor.minutes().toString()}
      >
        {minute}
      </DropdownItem>
    );
  });

  return <DropdownList isOpen={false}>{minutesList}</DropdownList>;
};

export interface EventCardReScheduleModalProps
  extends Omit<ModalProps, 'children'> {
  onSubmit: (id: string, scheduledFor: string) => void;
  event: ScheduledEvent;
  title: string;
  description: string;
  saveButtonLabel: string;
}

export const EventCardReScheduleModal = ({
  isOpen,
  onClose,
  onSubmit,
  event,
  title,
  description,
  saveButtonLabel,
}: EventCardReScheduleModalProps) => {
  const { t } = useTranslation('features.myPosts');

  const isDesktop = useIsDesktop();
  const cancelButtonVariant = isDesktop ? 'outline' : 'ghost';

  const [showDatePicker, setShowDatePicker] = useToggle(false);
  const [isAmPmDropdownOpen, setIsAmPmDropdownOpen] = useToggle(false);
  const [currentAmPm, setCurrentAmPm] = useState<string>(
    momentTimezone(event.scheduled_for).hour() < 12 ? 'AM' : 'PM',
  );

  const handleAmPmDropdown = (value: string) => {
    setCurrentAmPm(value);
    setEventScheduledFor(currentHour, currentMinute, value, currentDate[0]);
    setIsAmPmDropdownOpen(false);
  };

  const [isHourDropdownOpen, setIsHourDropdownOpen] = useToggle(false);
  const [currentHour, setCurrentHour] = useState<number>(
    momentTimezone(event.scheduled_for).hour() % 12,
  );
  const handleCurrentHourClick = (value: number) => {
    setCurrentHour(value);
    setEventScheduledFor(value, currentMinute, currentAmPm, currentDate[0]);
    setIsHourDropdownOpen(false);
  };

  const [isMinuteDropdownOpen, setIsMinuteDropdownOpen] = useToggle(false);
  const [currentMinute, setCurrentMinute] = useState<string>(
    momentTimezone(event.scheduled_for).minutes().toString().padStart(2, '0'),
  );
  const handleCurrentMinuteClick = (value: string) => {
    setCurrentMinute(value);
    setEventScheduledFor(currentHour, value, currentAmPm, currentDate[0]);
    setIsMinuteDropdownOpen(false);
  };

  const [currentDate, setCurrentDate] = useState<momentTimezone.Moment[]>([
    momentTimezone(event.scheduled_for).clone(),
  ]);
  const onChangeDatePicker = (value: momentTimezone.Moment[]) => {
    const date = value?.[1] ? [value?.[1].clone()] : [value[0].clone()];
    setCurrentDate(date);
    setEventScheduledFor(currentHour, currentMinute, currentAmPm, date[0]);
  };

  const [scheduledFor, setScheduledFor] = useState<string>(event.scheduled_for);

  const setEventScheduledFor = (
    hour: number,
    minute: string,
    amPm: string,
    date: momentTimezone.Moment,
  ) => {
    const formattedDate = date.clone().format('YYYY-MM-DD');
    let formattedHour = hour;
    if (amPm === 'PM') {
      if (hour !== 12) {
        formattedHour = hour + 12;
      }
    } else if (amPm === 'AM' && hour === 12) {
      formattedHour = 0;
    }

    const formattedTime = `${formattedHour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}`;

    setScheduledFor(
      createDateTimeFromISO(`${formattedDate}T${formattedTime}`).toString(),
    );
  };

  const handleSubmit = callAllHandlers(
    () => onSubmit(event.id, scheduledFor),
    onClose,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent minHeight="800px">
          <ModalHeader justifyContent="center" py={4}>
            <Box textStyle="body-normal-medium">{title}</Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={{ base: 6, m: 8 }} py={6}>
            <Box textStyle="heading-normal-medium" mb={2}>
              {title}
            </Box>
            <Box mb={2} textStyle="body-large-light">
              {description}
            </Box>
            <FormControl>
              <Box px={0} maxWidth={'546px'}>
                <FormLabel htmlFor="dropdown-date" pt={'24px'}>
                  {t('reschedule-post-modal.date')}
                </FormLabel>
                <Dropdown
                  data-testid={'reschedule-post-date-dropdown'}
                  closeOnClickOutside
                  withArrow
                  id={'dropdown-date'}
                  onToggle={setShowDatePicker}
                  isOpen={showDatePicker}
                  color={'primary'}
                  list={<></>}
                  closeOnFocusOutside={false}
                  disabled={false}
                  isAbsolute={false}
                >
                  {currentDate[0].format('DD MMM YYYY')}
                </Dropdown>
                <Box position="relative">
                  <Box
                    display={showDatePicker ? 'grid' : 'none'}
                    position="absolute"
                    mt="4"
                    width="100%"
                    zIndex={99999}
                  >
                    <DatePicker
                      value={currentDate}
                      onChange={onChangeDatePicker}
                      onClose={() => setShowDatePicker(false)}
                      onSave={() => setShowDatePicker(false)}
                      showFooter={true}
                    />
                  </Box>
                </Box>

                <Box px={0}>
                  <FormLabel htmlFor="dropdown-hour" pt={'24px'} width={'100%'}>
                    {t('reschedule-post-modal.time')}
                  </FormLabel>
                  <Box style={{ display: 'inline-flex' }} width={'100%'}>
                    <Box maxWidth={'185px'} pr={'8px'} width={'100%'}>
                      <Dropdown
                        data-testid={'reschedule-post-hour-dropdown'}
                        closeOnClickOutside
                        withArrow
                        id={'dropdown-hour'}
                        onToggle={setIsHourDropdownOpen}
                        isOpen={isHourDropdownOpen}
                        color={'primary'}
                        list={HourList(
                          momentTimezone(event.scheduled_for),
                          handleCurrentHourClick,
                        )}
                        closeOnFocusOutside={false}
                        disabled={false}
                        isAbsolute={false}
                      >
                        {currentHour.toString().padStart(2, '0')}
                      </Dropdown>
                    </Box>

                    <Box maxWidth={'185px'} pr={'8px'} width={'100%'}>
                      <Dropdown
                        data-testid={'reschedule-post-minute-dropdown'}
                        closeOnClickOutside
                        withArrow
                        id={'dropdown-minute'}
                        onToggle={setIsMinuteDropdownOpen}
                        isOpen={isMinuteDropdownOpen}
                        color={'primary'}
                        list={MinutesList(
                          momentTimezone(event.scheduled_for),
                          handleCurrentMinuteClick,
                        )}
                        closeOnFocusOutside={false}
                        disabled={false}
                        isAbsolute={false}
                      >
                        {currentMinute}
                      </Dropdown>
                    </Box>

                    <Box maxWidth={'185px'} width={'100%'}>
                      <Dropdown
                        data-testid={'reschedule-post-am-pm-dropdown'}
                        closeOnClickOutside
                        withArrow
                        id={'dropdown-am-pm'}
                        isOpen={isAmPmDropdownOpen}
                        color={'primary'}
                        closeOnFocusOutside={false}
                        disabled={false}
                        isAbsolute={false}
                        onToggle={setIsAmPmDropdownOpen}
                        list={
                          <DropdownList isOpen={false}>
                            <DropdownItem
                              onClick={() => handleAmPmDropdown('AM')}
                              isChecked={
                                momentTimezone(scheduledFor).hour() < 12
                              }
                            >
                              AM
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleAmPmDropdown('PM')}
                              isChecked={
                                momentTimezone(scheduledFor).hour() > 12
                              }
                            >
                              PM
                            </DropdownItem>
                          </DropdownList>
                        }
                      >
                        {currentAmPm}
                      </Dropdown>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </FormControl>
          </ModalBody>
          <ModalFooter
            px={6}
            py={4}
            flexDirection={{
              base: 'column-reverse',
              m: 'row',
            }}
            borderTop={{ m: 'none' }}
          >
            <Box
              as={Button}
              variant={cancelButtonVariant}
              onClick={onClose}
              mr={{ base: 0, m: 2 }}
              width={{ base: '100%', m: 200 }}
            >
              {t('general-modal.cancel')}
            </Box>
            <Box
              as={Button}
              onClick={handleSubmit}
              mb={{ base: 3, m: 0 }}
              width={{ base: '100%', m: 200 }}
            >
              {saveButtonLabel}
            </Box>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
